import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import 'antd/dist/antd.css';
// import './styles/custom.css'
import './styles/antd-custom.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './modules/core/services/authConfig';
import 'tailwindcss/tailwind.css';
import { MsalProvider } from '@azure/msal-react';
import Loader from './modules/core/ui/loader';

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance
  .handleRedirectPromise()
  .then((value) => {
    if (value != null) {
      sessionStorage?.setItem('authToken', value.accessToken);
      sessionStorage?.setItem('name', value.account?.name as string);
    }
  })
  .catch((error: any) => {
    //console.log('err');
    //console.log(error);
  });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<Loader size="large" />}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
