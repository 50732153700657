import * as React from 'react';
import { HeaderContextProvider } from '../../../core/ui/HeaderContextProvider/HeaderContextProvider';
import { MessageStripeContextProvider } from '../../../core/ui/MessageStripProvider/MessageStripProvider';
import Header from './Header';

type LayoutProps = {
  children?: React.ReactNode | React.ReactNode[];
};
const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <div className="ap-container mt-10 m-auto pb-10">
        <MessageStripeContextProvider>
          <HeaderContextProvider>{children}</HeaderContextProvider>
        </MessageStripeContextProvider>
      </div>
    </>
  );
};

export default Layout;
