import { Alert } from 'antd';
import * as React from 'react';

import { ALERT_MESSAGE_TYPES } from '../../models/constants/Core.Constants';
import { AlertMessage } from '../../models/interfaces/Common.interface';
const iconPath = process.env.PUBLIC_URL + '/images/';
type MessageStripeContextType = {
  alertMessageInfo: AlertMessage | undefined;
  setAlertMessgeInfo: (alertMessageInfo: AlertMessage) => void;
};
export const MessageStripeContext = React.createContext<MessageStripeContextType>({} as MessageStripeContextType);

type MessageStripeProviderProps = {
  children?: React.ReactNode | React.ReactNode[];
};
const useMessageStipe = () => {
  return React.useContext(MessageStripeContext);
};

const MessageStripeContextProvider = ({ children }: MessageStripeProviderProps) => {
  const [alertMessageInfo, setAlertMessgeInfo] = React.useState<AlertMessage | undefined>();
  return (
    <MessageStripeContext.Provider
      value={{
        alertMessageInfo,
        setAlertMessgeInfo,
      }}
    >
      {children}
    </MessageStripeContext.Provider>
  );
};

export { useMessageStipe, MessageStripeContextProvider };
