import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from '../models/enums/routes';

type PublicRoutesProps = {
  isRestricted: boolean;
};

const useAuth = () => {
  const user = sessionStorage.getItem('authToken');

  if (user) {
    return true;
  } else {
    return false;
  }
};

const PublicRoutes: React.FC<PublicRoutesProps> = ({ isRestricted }) => {
  const auth = useAuth();

  const restricted = isRestricted ? auth : false;

  return restricted ? <Navigate to={ROUTES.COMPETITIVE_GROUPS + ROUTES.LIST} replace /> : <Outlet />;
};

export default PublicRoutes;
