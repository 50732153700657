import { GoogleOAuthProvider } from '@react-oauth/google';
import * as React from 'react';
import { GOOGLE_ACCOUNT_DATA } from '../../core/models/enums/core.enums';
import { domainServices } from './domains/services/domains.services';
import FeatureRoutes from './FeatureRoutes';
import Layout from './Layout';

type FeatureProps = {
  //
};

const Feature: React.FC<FeatureProps> = () => {
  /* const [clientId, setClientId] = React.useState<string | undefined>(undefined);
  React.useEffect(()=>{
    domainServices.getApaxAccountInfo().then((response: any)=>{ 
      setClientId(response.data.collection[0].clientId);
    })
  }, []) */

  return (
    <Layout>
      <FeatureRoutes></FeatureRoutes>
    </Layout>
  );
};

export default Feature;
