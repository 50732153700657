import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../../core/models/enums/routes';

const JobListContainer = React.lazy(() => import('./jobs-list/container/jobListContainer/JobListContainer'));
type Props = {};

const JobRoutes = (props: Props) => {
  return (
    <Routes>
      <Route path={ROUTES.LIST} element={<JobListContainer />}></Route>
      <Route path={ROUTES.BASE} element={<Navigate to={ROUTES.JOBS_QUEUE + ROUTES.LIST} replace />}></Route>
    </Routes>
  );
};

export default JobRoutes;
