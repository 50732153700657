import * as React from 'react';
import JobsRoutes from './JobRoutes';

type JobsProps = {
  //
};

const Jobs: React.FC<JobsProps> = () => {
  return (
    <>
      <JobsRoutes />
    </>
  );
};

export default Jobs;
