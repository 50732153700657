import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../core/models/enums/routes';
import { loginRequest } from '../../../core/services/authConfig';
import Loader from '../../../core/ui/loader';

type LoginContainerProps = {
  //
};

const LoginContainer: React.FC<LoginContainerProps> = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  React.useEffect(() => {
    if (!isAuthenticated) {
      instance
        .loginRedirect(loginRequest)
        .then((data) => {
          // //console.log("some data", data);
        })
        .catch((e) => {
          //console.log("error from msal", e);
        });
    } else {
      navigate(ROUTES.COMPETITIVE_GROUPS + ROUTES.LIST);
    }
  }, [isAuthenticated]);

  return (
    <>
      <Loader size="large" />
    </>
  );
};

export default LoginContainer;
