import { Breadcrumb } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { BreadCrumbInterface } from '../../models/interfaces/Common.interface';

interface BreadCrumbProps {
  breadCrumbInfo: BreadCrumbInterface[] | undefined;
}

const APBreadCrumb: React.FC<BreadCrumbProps> = ({ breadCrumbInfo }) => {
  return (
    <>
      {breadCrumbInfo && breadCrumbInfo.length && (
        <div className="ap-breadcumb mb-6 mt-10">
          <Breadcrumb>
            {breadCrumbInfo.map((breadCrumbitem, index) => (
              <Breadcrumb.Item key={index}>
                {breadCrumbitem?.link && breadCrumbitem.label && (
                  <Link to={breadCrumbitem?.link}>{breadCrumbitem?.label}</Link>
                )}
                {!breadCrumbitem?.link && breadCrumbitem.label && breadCrumbitem.label}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
      )}
    </>
  );
};

export default APBreadCrumb;
