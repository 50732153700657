import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginContainer from './modules/auth/container/loginContainer';

import { ROUTES } from './modules/core/models/enums/routes';
import ProtectedRoutes from './modules/core/protectedRoutes';
import PublicRoutes from './modules/core/publicRoutes';
import Feature from './modules/features/Feature';

type Props = {};

const AppRoutes = (props: Props) => {
  return (
    <>
      <Routes>
        <Route path={ROUTES.BASE} element={<ProtectedRoutes isRestricted={true} />}>
          <Route path={ROUTES.ANY} element={<Feature />}></Route>
        </Route>
        <Route path={ROUTES.BASE} element={<PublicRoutes isRestricted={true} />}>
          <Route path={ROUTES.LOGIN} element={<LoginContainer />}></Route>
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
