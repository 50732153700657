import { useIsAuthenticated } from '@azure/msal-react';
import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ROUTES } from '../models/enums/routes';

type ProtectedRoutesProps = {
  isRestricted: boolean;
};

const ProtectedRoutes: React.FC<ProtectedRoutesProps> = () => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  return isAuthenticated ? (
    location.pathname === '/' ? (
      <Navigate to={ROUTES.COMPETITIVE_GROUPS + ROUTES.LIST} replace />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to={ROUTES.LOGIN} replace />
  );
};

export default ProtectedRoutes;
