export enum ROUTES {
  BASE = '/',
  PAGE_NOT_FOUND = '*',
  LOGIN = '/login',
  AUTH = '/auth',
  COMPETITIVE_GROUPS = '/competitive_groups',
  DOMAINS = '/domains',
  JOBS_QUEUE = '/jobs_queue',
  LIST = '/list',
  COMPETITIVE_GROUPS_MODIFY_WITH_ID = '/modify/:id',
  COMPETITIVE_GROUPS_MODIFY = '/modify/',
  REFERENCE_DATA_FUNDS = '/funds',
  REFERENCE_DATA_TAGS = '/tags',
  ANY = '*',
  ANY_SEPRATOR = '/*',
  ADD = '/add',
  REFERENCE_DATA = '/reference_data',
  REFERENCE_DATA_INDUSTRIES = '/industries',
  REFERENCE_DATA_APAX_SECTORS = '/apax_sectors',
  REFERENCE_DATA_BUSINESS_MODELS = '/business_models',
  REFERENCE_GOOGLE_ANALYTICS_GOALS = '/google-analytics-goals',
  REFERENCE_GOOGLE_ANALYTICS_EVENTS = '/google-analytics-events',
  REFERENCE_DATA_CHANNEL_MAPPING = '/channel_mapping',
  DATA_MANAGEMENT = '/data_management',
  GA = '/ga/callback',
}
